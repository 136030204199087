.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1920px;
  height: 150px;
  margin: 0 auto;
  z-index: 1;

  .logo img {
    width: 100%;
    max-width: 300px;
    cursor: pointer;
  }

  .hamburger-react {
    margin-right: 50px;
    z-index: 99999;
    color: rgb(77, 77, 77);
  }
  &.nav-open {
    .hamburger-react {
      color: white;
    }
  }
}

@media (max-width: 768px) {
  .header-container {
    .hamburger-react {
      margin-right: 50px;
      z-index: 99999;
      color: rgb(132, 132, 132);
    }
    &.nav-open {
      .hamburger-react {
        color: white;
      }
    }
  }
}
