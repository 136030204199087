.container {
  .terms-content {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 10px 50px 10px;
    box-sizing: border-box;

    .data_police {
      list-style-type: none;
      padding-top: 5px;
      margin: 0;
      font-weight: lighter;
    }

    ul {
      li {
        font-weight: lighter !important;
      }
    }
    
    h4 {
      font-size: 20px;
      font-weight: bold;
    }

    p {
      font-weight: lighter;
    }
  }
}
