.partners-container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 60px 0;
  position: relative; 

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public/assets/world-map2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    z-index: -1; 
  }


  .partners-title {
    text-align: center;
    padding-bottom: 60px;

    .title {
      font-size: 38px;
      padding: 0 10px;
      margin-block-start: 0;
      margin-block-end: 0;
    }
    .subtitle {
      padding: 0 10px;
      margin-block-start: 0;
      margin-block-end: 0;
      font-weight: 400;
    }
  }

  .partners-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 20px;
    padding: 0 10px;

    .partners-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      width: 100%;
      max-width: 1400px;

      .partner-box {
        width: 300px;
        height: 400px;
        background: #f4f3ec;
        display: flex;
        border: 1px solid #e7e7e7;
        border-radius: 70px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        overflow: hidden;

        &::before {
          content: "";
          position: absolute;
          top: -100%;
          right: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
          transition: top 0.7s ease-in-out;
        }

        &:hover::before {
          top: 0;
        }

        .partners-image {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 150px;
          z-index: 1;

          img {
            width: 100%;
            max-width: 150px;
          }
        }

        .partners-name {
          z-index: 1;
          .partner-name {
            text-align: center;
            font-weight: 400;
          }
        }

        &:nth-child(even) {
          margin-top: -60px;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .partners-container {
    .partners-main {
      .partners-list {
        .partner-box {
          width: 300px;
          height: 400px;
          &:nth-child(even) {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .partners-container {
    .partners-title {
      .title {
        font-size: 28px;
      }
    }
    .partners-main {
      .partners-list {
        .partner-box {
          width: 200px;
          height: 300px;
          border-radius: 40px;
          .partners-image {
            max-width: 120px;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .partners-container {
    .partners-main {
      .partners-list {
        .partner-box {
          width: 170px;
          height: 250px;

          .partners-name {
            .partner-name {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
