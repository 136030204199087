.palletdelivery-container {
    width: 100%;
    max-width: 1920px;
    background: rgb(34, 35, 38);
    margin: 0 auto;
    padding: 100px 0;

    .palletdelivery-content {
      width: 100%;
      max-width: 1320px;
      display: flex;
      gap: 40px;
      justify-content: center;
      margin: 0 auto;
  
      .palletdelivery-image {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
  
        .img1 {
          width: 483px;
          grid-area: 1 / 1 / 3 / 2;
        }
        .img2 {
          width: 250px;
          grid-area: 1 / 2 / 2 / 3;
        }
        .img3 {
          width: 250px;
          grid-area: 2 / 2 / 3 / 3;
        }
      }

      .palletdelivery-text {
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
  
        .above-title {
          font-size: 12px;
          color: #3667a3;
          font-weight: 500;
          margin-block-start: 0;
          margin-block-end: 0;
        }
  
        .service-title {
          color: white;
          margin-block-start: 0;
          margin-block-end: 0;
        }
  
        .description {
          font-size: 14px;
          text-align: justify;
          font-weight: 400;
        }
      }
  
      
    }
  
   
  }
  
  @media (max-width: 1300px) {
    .palletdelivery-container {
      .title-main {
        padding: 60px 0 40px 0;
      }
  
      .palletdelivery-content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
  
        .palletdelivery-text,
        .palletdelivery-text {
          text-align: center;
        }
  
        .palletdelivery-image {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: none;
  
          .img1 {
            grid-area: 1 / 1 / 2 / 3;
          }
          .img2 {
            width: 240px;
            grid-area: 2 / 1 / 3 / 2;
          }
          .img3 {
            width: 240px;
            grid-area: 2 / 2 / 3 / 3;
          }
        }
      }
    }
  }
  
  @media (max-width: 1045px) {
    .palletdelivery-container {
      width: 100%;
      max-width: 1920px;
      background: rgb(34, 35, 38);
      margin: 0 auto;
      padding: 60px 0 40px 0;
  
      .palletdelivery-content {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .palletdelivery-text {
          .palletdelivery-title {
            font-size: 22px;
          }
        }
      }
      .palletdelivery-button {
        display: flex;
        justify-content: center;
      }
    }
  }
  
  @media (max-width: 515px) {
    .palletdelivery-container {
  
      .palletdelivery-content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        flex-direction: column;
        align-items: center;
  
        .palletdelivery-text {
          text-align: center;

          .description {
            padding: 0 10px;
          }
        }
  
        .palletdelivery-image {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
  
          .img1 {
            width: 100%;
            max-width: 360px;
          }
          .img2, .img3 {
            width: 100%;
            max-width: 180px;
          }
        }
      }
    }
  }
  