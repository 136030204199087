.storage-container {
    width: 100%;
    max-width: 1920px;
    background: rgb(34, 35, 38);
    margin: 0 auto;
    padding: 0px 0 20px 0;
  
    .storage-content {
      width: 100%;
      max-width: 1320px;
      display: flex;
      gap: 40px;
      justify-content: center;
      margin: 0 auto;
  
      .storage-image {
        width: 100%;
        max-width: 600px;
        padding-top: 80px;
  
        .img {
          width: 100%;
        }
       
      }
  
      .storage-text {
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
  
        .above-title {
          font-size: 12px;
          color: #3667a3;
          font-weight: 500;
          margin-block-start: 0;
          margin-block-end: 0;
        }
  
        .service-title {
          color: white;
          margin-block-start: 0;
          margin-block-end: 0;
        }
  
        .description {
          font-size: 14px;
          text-align: justify;
          font-weight: 400;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
        }

        .storage-services {
            font-size: 14px;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            padding-inline-start: 0px;
            gap: 20px;
    
            li {
              list-style: none;
              display: flex;
              flex-direction: column;
              align-items: start;
              
    
              .inline-icon {
                display: flex;
                gap: 10px;
              }
    
              span {
                padding-top: 2px;
                font-weight: 400;
              }
    
              .icon {
                fill: #8f8f8f;
                stroke: #2a2a2a;
                stroke-width: 2;
                width: 20px;
              }
            }
          }
      }
    }
  }
  
  @media (max-width: 1300px) {
    .storage-container {
      .title-main {
        padding: 60px 0 40px 0;
      }
  
      .storage-content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
  
        .storage-text,
        .storage-text {
          text-align: center;
        }
  
      }
    }
  }
  
  @media (max-width: 1045px) {
    .storage-container {
      width: 100%;
      max-width: 1920px;
      background: rgb(34, 35, 38);
      margin: 0 auto;
      padding: 20px 0 40px 0;
  
      .storage-content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
  
        .storage-text {
          .storage-title {
            font-size: 22px;
  
            .description {
              font-size: 14px;
              text-align: center;
              font-weight: 400;
            }
          }
        }
      }
      .storage-button {
        display: flex;
        justify-content: center;
      }
    }
  }
  
  @media (max-width: 515px) {
    .storage-container {
      .storage-content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        flex-direction: column-reverse;
        align-items: center;
  
        .storage-text {
          text-align: center;
  
          .description {
            padding: 0 10px;
          }

          .storage-services {
            li {
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: center;
            }  
         }
        }
  
        .storage-image {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
  
          .img {
            width: 100%;
            max-width: 360px;
          }
        }
      }
    }
  }
  