.home-container {
  background: url("../../public/assets/map_location.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-color: rgb(34, 35, 38);
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;

  .header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .header-title {
      padding-top: 100px;
    }

    .header-button {
      margin-top: 20px;
    }
    .image-content {
      padding-bottom: 100px;

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .home-container {
    background-size: cover;
    background-position: left;

    .header-content {
      .header-title {
        padding-top: 50px;
      }

      .header-button {
        margin-top: 20px;
      }
      .image-content {
        padding-bottom: 50px;

        img {
          max-width: 450px;
        }
      }
    }
  }
}
