.info-container {
  width: 100%;
  max-width: 1920px;
  background-color: #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin: 0 auto;
  padding: 50px 0;

  .info-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    .circle {
      border: 1px solid #e5e5e5;
      border-radius: 100%;
      padding: 10px;

      .icon-circle {
        background-color: white;
        border: 1px solid #e1e1e1;
        border-radius: 100%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: #f9c404;
          stroke: #000000;
          stroke-width: 2;
        }
        .icon {
          width: 50px;
          color: gray;
        }
      }
    }

    .info-text {
      color: #727272;
    }
  }
}
