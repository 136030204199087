.main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  position: relative;


  .title {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-text {
      font-size: 80px;
      color: white;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
  }

  .animated-title {
    display: flex;
    justify-content: center;
    height: 100px;
    

    #animated-title_text {
      color: white;
      font-size: 80px;
      opacity: 0;
      font-weight: bold;
      animation: appear 0.5s forwards;
    }
  }
}

@keyframes appear {
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .main {
    .title {
      .title-text {
        font-size: 40px;
      }
    }

    .animated-title {
      #animated-title_text {
        font-size: 40px;
      }
    }
  }
}

@media (max-width: 500px) {
  .main {
    .main {
      .title {
        .title-text {
          font-size: 40px;
        }
      }

      .animated-title {
        #animated-title_text {
          font-size: 40px;
        }
      }
    }
  }
}
