.furniture-container {
  width: 100%;
  max-width: 1920px;
  background: rgb(34, 35, 38);
  padding: 60px 0;
  margin: 0 auto;

  .furniture-content {
    width: 100%;
    max-width: 1320px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
    margin: 0 auto;

    .furniture-text {
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      padding-top: 20px;

      .above-title {
        font-size: 12px;
        color: #3667a3;
        font-weight: 500;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .furniture-title {
        color: white;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        gap: 20px;

        li {
          list-style: none;
          display: flex;
          flex-direction: column;
          align-items: start;
          

          .inline-icon {
            display: flex;
            gap: 10px;
          }

          span {
            padding-top: 2px;
            font-weight: 400;
          }

          .icon {
            fill: #8f8f8f;
            stroke: #2a2a2a;
            stroke-width: 2;
            width: 20px;
          }
        }
      }
      .furniture-button {
        padding-top: 20px;
      }
    }

    .furniture-image {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto auto;
      grid-column-gap: 5px;
      grid-row-gap: 5px;
      padding: 100px 10px;
      

      .img1 {
        width: 100%;
        max-width: 500px;
        grid-area: 1 / 1 / 2 / 3;
      }
      .img2 {
        width: 100%;
        max-width: 245px;
        grid-area: 2 / 1 / 3 / 2;
      }
      .img3 {
        width: 100%;
        max-width: 245px;
        grid-area: 2 / 2 / 3 / 3;
      }
    }
  }
}

@media (max-width: 1040px) {
  .furniture-container {
    width: 100%;
    max-width: 1920px;
    background: rgb(34, 35, 38);
    padding: 60px 0;

    .furniture-content {
      gap: 40px;
      justify-content: space-evenly;
      margin: 0 auto;

      .furniture-text {
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        padding-top: 20px;

        .above-title { 
          text-align: center;
        }

        .furniture-title {
          text-align: center;
          padding: 0 10px;
        }

        .description {
          padding-inline-start: 30px;
          padding-top: 20px;
        }
        .furniture-button {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
