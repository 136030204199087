.statistic_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;

  .title-main {
    padding-top: 30px;
    text-align: center;

    .statistic-title {
      font-size: 38px;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  .subtitle-main {
    padding-top: 20px;
    text-align: center;

    .statistic-subtitle {
      font-size: 20px;
      margin-block-start: 0;
      margin-block-end: 0;
      font-weight: 400;
    }
  }

  .image-content {
    img {
      width: 100%;
      max-width: 600px;
    }
  }
  .individual-statistic {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;
    max-width: 1400px;
    padding: 30px 0;

    .year,
    .kilometers,
    .packages,
    .furniture {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #727272;
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      max-width: 300px;

      .statistic-label {
        font-size: 16px;
        color: #f9c404;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 1024px) { 
  .statistic_container {
  
    .title-main {
      .statistic-title {
        font-size: 28px;
      }
    }
    .subtitle-main {
  
      .statistic-subtitle {
        font-size: 16px;
      }
    }
  }
}
