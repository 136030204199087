.footer {
  max-width: 1920px;
  background: rgb(34, 35, 38);
  color: white;
  padding: 50px 0 20px 0;
  margin: 0 auto;

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .company-info {
    img {
      width: 100%;
      max-width: 300px;
    }
  }
  .company-info,
  .services,
  .contact-us,
  .bank {
    flex-basis: 300px;
    margin-bottom: 30px;
  }

  .company-info h2,
  .services h2,
  .contact-us h2,
  .bank h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .services ul,
  .contact-us ul,
  .bank ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .border {
    height: 1px;
    border-bottom: 1px solid white;
    margin-top: -4px;
    margin-bottom: 8px;
  }

  .services li,
  .contact-us li,
  .bank li {
    margin-bottom: 10px;
  }

  

  .company-info p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  .company-info p:last-of-type {
    margin-bottom: 0;
  }

  .contact-us ul {
    font-size: 1rem;
  }

  .contact-us li {
    color: white;

    &:hover {
      text-decoration: underline;
    }

    a {
      color: white;
      text-decoration: none;
      cursor: pointer;
      border-bottom: none;
    }
  }

  .copyright {
    text-align: center;
  }

  .legal-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .p-link {
      cursor: pointer;
    }
  }
  
  .link {
    margin-top: 20px;
    cursor: pointer;

    &-to {
      color: white;
      text-decoration: none;
      cursor: pointer;

    }
  }

  @media (max-width: 1180px) {
    .footer-container {
      margin: 10px;
      justify-content: center;

      .company-info,
      .services,
      .contact-us,
      .bank {
        flex-basis: 100%;
        text-align: center;
      }

      .company-info p {
        max-width: 300px;
        margin: 0 auto;
      }

      .contact-us button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
      }
    }
  }

  @media (max-width: 768px) {
    .footer-container {
      margin: 10px;
      justify-content: center;

      .company-info,
      .services,
      .contact-us,
      .bank {
        flex-basis: 100%;
        text-align: center;
      }

      .company-info p {
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
}
