.animated-section {
    opacity: 0;
    transform: scale(0.9);
    transition: transform 0.8s ease-out, opacity 0.8s ease-out;
  
    &.in-view {
      opacity: 1;
      transform: scale(1);
    }
  }
  