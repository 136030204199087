.service-container {
  width: 100%;
  max-width: 1920px;
  background: rgb(34, 35, 38);
  margin: 0 auto;

  .title-main {
    text-align: center;
    padding: 60px 0;

    .transport-title {
      font-size: 38px;
      color: white;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
  .transport-content {
    width: 100%;
    max-width: 1320px;
    display: flex;
    gap: 40px;
    justify-content: center;
    margin: 0 auto;

    .transport-text {
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      padding-top: 20px;

      .above-title {
        font-size: 12px;
        color: #3667a3;
        font-weight: 500;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .service-title {
        color: white;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .description {
        font-size: 14px;
        text-align: justify;
        font-weight: 400;
      }
    }

    .delivery-image {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 5px;
      grid-row-gap: 5px;

      .img1 {
        width: 483px;
        grid-area: 1 / 1 / 3 / 2;
      }
      .img2 {
        width: 250px;
        grid-area: 1 / 2 / 2 / 3;
      }
      .img3 {
        width: 250px;
        grid-area: 2 / 2 / 3 / 3;
      }
    }
  }

  .international-content {
    width: 100%;
    max-width: 1320px;
    min-height: 500px;
    display: flex;
    gap: 40px;
    justify-content: center;
    margin: 0 auto;
    padding-top: 60px;

    .transport-image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 80px;
      
      .route-image {
        width: 100%;
        max-width: 600px;
      
      }
    }
    .delivery-text {
      width: 100%;
      max-width: 500px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-top: 20px;

      .above-title {
        font-size: 12px;
        color: #3667a3;
        font-weight: 500;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .service-title {
        color: white;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .description {
        font-size: 14px;
        text-align: justify;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 1300px) {
  .service-container {
    .title-main {
      padding: 60px 0 40px 0;
    }

    .transport-content {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .transport-text,
      .delivery-text {
        padding: 0 10px;
        text-align: center;
      }

      .delivery-image {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: none;
        padding: 0 10px;

        .img1 {
          grid-area: 1 / 1 / 2 / 3;
        }
        .img2 {
          width: 240px;
          grid-area: 2 / 1 / 3 / 2;
        }
        .img3 {
          width: 240px;
          grid-area: 2 / 2 / 3 / 3;
        }
      }
    }
    .international-content {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding-top: 40px;

      .delivery-text {
        padding: 0 10px;
        text-align: center;
        order: -1;
      }
    }
  }
}

@media (max-width: 1045px) {
  .service-container {
    width: 100%;
    max-width: 1920px;
    background: rgb(34, 35, 38);
    margin: 0 auto;

    .title-main {
      text-align: center;
      padding: 60px 0;

      .transport-title {
        font-size: 28px;
        color: white;
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
    .transport-content {
      .transport-text {
        .service-title {
          font-size: 22px;
        }
      }
    }
    .transport-button {
      display: flex;
      justify-content: center;
    }

    .international-content {
      .delivery-text {
        .service-title {
          font-size: 22px;
        }
      }
    }
  }
}

@media (max-width: 515px) {
  .service-container {
    .title-main {
      padding: 60px 0 20px 0;
    }

    .transport-content {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .transport-text,
      .delivery-text {
        padding: 0 10px;
        text-align: center;
      }

      .delivery-image {
        padding: 0 10px;

        .img1 {
          width: 100%;
          max-width: 360px;
          grid-area: 1 / 1 / 2 / 3;
        }
        .img2 {
          width: 100%;
          max-width: 180px;
          grid-area: 2 / 1 / 3 / 2;
        }
        .img3 {
          width: 100%;
          max-width: 180px;
          grid-area: 2 / 2 / 3 / 3;
        }
      }
    }

    .international-content {
      padding-top: 60px;
  
      .transport-image {
        padding-right: 0;

        .route-image {
          width: 100%;
          max-width: 390px;
        }
      }
    }
  }
}
