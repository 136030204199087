.menu {
  height: 100vh;
  width: 100%; 
  max-width: 450px;
  background-color: #3e3e3e;
  position: fixed;
  right: 0;
  top: 0;
  color: white;
  z-index: 999;

  .body {
    box-sizing: border-box;
    height: 100%;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .nav {
      display: flex;
      flex-direction: column;
      font-size: 56px;
      gap: 12px;
      margin-top: 20px;

      .nav-header {
        color: rgb(153, 153, 153);
        border-bottom: 1px solid rgb(153, 153, 153);
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 40px;
      }

      a {
        text-decoration: none;
        color: white;
        font-weight: 300;
      }
    }
  }

  @media (max-width: 768px) {
    .body{
      width: 100%; 

      .nav{
        
        a {
          font-size: 40px;
          text-align: center;
        }
      }
    }
    
  }
}
