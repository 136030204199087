.social-icon {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 20px 20px;
  box-sizing: border-box;
  cursor: pointer;

  .social-icon__list {
    list-style: none;
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0;
    flex: 1;

    li {
      &:hover {
        transform: scale(1.4); 
        transition: transform 0.3s ease; 
      }
    }
  }
}
