.contact-button {
  width: 220px;
  height: 50px;
  padding: 0 15px;
  background: rgb(171, 2, 2);
  background: -moz-radial-gradient(
    circle,
    rgba(171, 2, 2, 1) 1%,
    rgba(135, 1, 1, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(171, 2, 2, 1) 1%,
    rgba(135, 1, 1, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(171, 2, 2, 1) 1%,
    rgba(135, 1, 1, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab0202",endColorstr="#870101",GradientType=1);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: rgb(143, 44, 20);
    background: linear-gradient(
      90deg,
      rgba(143, 44, 20, 1) 35%,
      rgba(159, 15, 15, 0.9668242296918768) 100%
    );
  }
}

.btn-contact {
  width: 150px;
  height: 50px;
  background: #f7c719;
  font-weight: 600;
  color: black;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-top: 30px;
  transition: background-color 0.5s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f7c719;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
  }

  &:hover:before {
    transform: scaleX(1);
    transform-origin: left;
  }

  &:hover {
    background-color: #ffc800ad;
  }

  &:active, &:focus {
    background-color: #f7c719;
  }
}




.read_more {
  width: 150px;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(74, 137, 232, 1) 1%,
    rgba(4, 72, 176, 1) 100%
  );
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 30px;

  &:hover {
    background: linear-gradient(
      90deg,
      rgb(37, 116, 235) 1%,
      rgb(1, 41, 102) 100%
    );
  }
}

.backToTopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: linear-gradient(
    90deg,
    rgba(74, 137, 232, 1) 1%,
    rgba(4, 72, 176, 1) 100%
  );

  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  color: #fff;
  border: none;
  border-radius: 100%;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  z-index: 99;
  transition: background-color 0.3s;

  &.visible {
    display: block;
  }

  &.hidden {
    display: none;
  }

  &:hover {
    background: linear-gradient(
      90deg,
      rgb(37, 116, 235) 1%,
      rgb(1, 41, 102) 100%
    );
  }
}
